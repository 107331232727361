// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-cookies-tsx": () => import("./../../../src/pages/politica-de-cookies.tsx" /* webpackChunkName: "component---src-pages-politica-de-cookies-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-sobre-nos-tsx": () => import("./../../../src/pages/sobre-nos.tsx" /* webpackChunkName: "component---src-pages-sobre-nos-tsx" */),
  "component---src-pages-stripe-success-tsx": () => import("./../../../src/pages/stripe-success.tsx" /* webpackChunkName: "component---src-pages-stripe-success-tsx" */),
  "component---src-pages-termos-tsx": () => import("./../../../src/pages/termos.tsx" /* webpackChunkName: "component---src-pages-termos-tsx" */)
}

